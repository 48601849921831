<template>
  <span>
    <v-row dense justify="center">
      <v-col
        v-for="kpi in listaKpisCardInicial" 
        :key="kpi.value"
        cols="auto"
        class="flex-grow-1"
      >
        <v-card class="my-0">
          <v-card-title>
            <v-tooltip bottom v-if="traducaoIndicadores[kpi.value] != null">
              <template v-slot:activator="{ on }">
                <h5 v-on="on">{{ kpi.text }}</h5>
              </template>
              <span >{{ traducaoIndicadores[kpi.value] }}</span>
            </v-tooltip>
            <h5 v-else >{{ kpi.text }}</h5>
            <status-evolucao-kpis
              class="ml-1"
              :valorAtual="dadosKpisMunicipioSelecionado == null ? 0 : dadosKpisMunicipioSelecionado[kpi.value] || 0"
              :valorAnterior="dadosKpisMunicipioSelecionadoCompetenciaAnterior == null ? 0 : dadosKpisMunicipioSelecionadoCompetenciaAnterior[kpi.value] || 0"
            />
          </v-card-title>
          <v-card-text>
            <div class="display-3">
              {{ dadosKpisMunicipioSelecionado == null ? 0 : dadosKpisMunicipioSelecionado[kpi.value] | parseNumberToFloatBrIgnoringNaN }} 
              <span>{{ kpi.suffix }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense justify="center">
      <v-col
        cols="12" md="9"
        class="flex-md-grow-1"
      >
        <v-card class="my-1">
          <v-card-text>
            <dashboard-servicos-visao-municipios-kpis-data-table           
              :dadosKpisByMunicipio="dadosKpisMunicipio"
              :traducaoIndicadores="traducaoIndicadores"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12" md="auto"
        class="flex-md-grow-1"
      >
        <v-card class="my-1">
          <v-card-text>
            <dashboard-servicos-visao-municipios-kpis-map 
              :dadosKpis="dadosKpisMunicipio" 
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <dashboard-servicos-visao-municipios-cards-kpis-por-municipio 
      :dados="dadosKpisMunicipio"
      :metasKpis="metasKpisMunicipio"
      :municipios="dadosKpisMunicipio.map(dado => dado.nome_municipio)"
      :tipoOrdenacao="tipoOrdenacao"
      :kpiOrdenacao="kpiOrdenacao"
      :exportingOptions="exportingOptions"
    />
  </span>
</template>

<script>

import DashboardServicosService from "@/services/DashboardServicosService";

import Highcharts from 'highcharts';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
HighchartsNoData(Highcharts);

import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';
exporting(Highcharts);
exportData(Highcharts);

import dayjs from 'dayjs';

export default {
  name: "DashboardServicosVisaoMunicipios",
  components: {
    StatusEvolucaoKpis: () => import("@/components/dashboardLno/StatusEvolucaoKpis.vue"),
    DashboardServicosVisaoMunicipiosKpisDataTable: () => import("@/components/dashboardServicos/DashboardServicosVisaoMunicipiosKpisDataTable.vue"),
    DashboardServicosVisaoMunicipiosKpisMap: () => import("@/components/dashboardServicos/DashboardServicosVisaoMunicipiosKpisMap.vue"),
    DashboardServicosVisaoMunicipiosCardsKpisPorMunicipio: () => import("@/components/dashboardServicos/DashboardServicosVisaoMunicipiosCardsKpisPorMunicipio.vue"),
  },
  props: {
    competencia: {
      type: String,
      required: true,
    },
    servicoSelecionado: {
      type: Number,
      required: true,
    },
    municipios: {
      type: Array,
      required: true,
    },
    municipioSelecionado: {
      type: String,
      required: true,
    },
    tipoOrdenacao: {
      type: String,
      required: true,
    },
    kpiOrdenacao: {
      type: String,
      required: true,
    },
    activatedTab: {
      type: Number,
      required: true,
    },
    traducaoIndicadores: {
      type: Object,
      default: {}
    },
    regionalSelecionada: {
      type: Number,
      required:true
    }
  },
  data: () => ({
    listaKpisCardInicial: [
      { value: "pendentes", text: "Pendentes", suffix: "" },
      { value: "pendentes_no_prazo", text: "Pendentes no Prazo", suffix: "" },
      { value: "pendentes_fora_prazo", text: "Pendentes Fora Prazo", suffix: "" },
      { value: "psa", text: "PSA", suffix: "%" },
      { value: "pat", text: "PAT", suffix: "" },
      { value: "suspensos", text: "Suspensos", suffix: "" },
      { value: "concluidos", text: "Concluídos", suffix: "" },
      { value: "concluidos_no_prazo", text: "Concluídos no Prazo", suffix: "" },
      { value: "concluidos_fora_prazo", text: "Concluídos Fora Prazo", suffix: "" },
      { value: "psfp", text: "PSFP", suffix: "%" },
      { value: "patc", text: "PATC", suffix: "" },
      { value: "solicitacoes", text: "Solicitações", suffix: "" },
      { value: "compensacoes", text: "Compensação", suffix: "" },
    ],
    dadosKpisMunicipio: [],
    dadosKpisTotalizadosMunicipio: [],
    dadosKpisTotalizados: [],
    dadosKpisTotalizadosCompetenciaAnterior: [],
    metasKpisMunicipio: {},
    exportingOptions:{
      enabled: true,
        buttons: {
            contextButton: {
              align: 'right',
              verticalAlign: 'bottom',
              menuItems: [
                    {
                        textKey: 'downloadPNG',
                        text: 'Baixar como PNG',
                        onclick: function () {
                            this.exportChart();
                        }
                    },
                    {
                        separator: true
                    },
                    {
                        textKey: 'downloadCSV',
                        text: 'Baixar como CSV',
                        onclick: function () {
                            this.downloadCSV();
                        }
                    },
                    {
                        textKey: 'downloadXLS',
                        text: 'Baixar como XLS',
                        onclick: function () {
                            this.downloadXLS();
                        }
                    }
              ]
            }
        }
    }
  }),
  computed: {
    dadosKpisMunicipioSelecionado() {
      if (this.municipioSelecionado != null && this.municipioSelecionado != undefined) {
        let index = this.dadosKpisMunicipio.findIndex(dados => dados.nome_municipio == this.municipioSelecionado);

        return index == -1
          ? null
          : this.dadosKpisMunicipio[index];
      }


      return this.dadosKpisTotalizados;

    },
    dadosKpisMunicipioSelecionadoCompetenciaAnterior() {
      if (this.municipioSelecionado != null && this.municipioSelecionado != undefined) {
        let index = this.dadosKpisTotalizadosMunicipio.findIndex(dados => dados.nome_municipio == this.municipioSelecionado);

        return index == -1
          ? null
          : this.dadosKpisTotalizadosMunicipio[index];

      }

      return this.dadosKpisTotalizadosCompetenciaAnterior

    },
  },
  mounted() {
    this.getDados();
  },
  methods: {
    getDados() {
      // Apenas atualiza os dados se for a visao selecionada
      if (this.activatedTab !== 2) return; 

      this.$emit("updateLoadingDados", true);

      Promise.all([
        this.getMetasKpisByMunicipio(),
        this.getDadosKpisMunicipio(),
        this.getDadosKpisMunicipioCompetenciaAnterior(),
        this.getDadosKpisCards(),
        this.getDadosKpisCardsCompetenciaAnterior()
      ])
        .catch(err => {
          console.error(err);
          this.$toast.error('Erro recuperar dados.', '', { position: 'topRight' });
        })
        .finally(() => this.$emit("updateLoadingDados", false));
    },
    getDadosKpisCards() {
      return DashboardServicosService.getDadosKpisGeraisVisaoMunicipiosCards(
        this.competencia,
        this.servicoSelecionado,
        this.regionalSelecionada
      )
        .then(res => {
          this.dadosKpisTotalizados = res;
        })
        .catch(err => {
          throw err;
        })
    },
    getDadosKpisCardsCompetenciaAnterior() {
      return DashboardServicosService.getDadosKpisGeraisVisaoMunicipiosCards(
        dayjs(this.competencia).subtract(1, 'month').format('YYYY-MM'),
        this.servicoSelecionado,
        this.regionalSelecionada
      )
        .then(res => {
          this.dadosKpisTotalizadosCompetenciaAnterior = res;
        })
        .catch(err => {
          throw err;
        });
    },
    getDadosKpisMunicipio() {
      return DashboardServicosService.getDadosKpisTotalizadosVisaoMunicipios(
        this.competencia,
        this.servicoSelecionado,
        this.regionalSelecionada
      )
        .then(res => {
          this.dadosKpisMunicipio = res;
        })
        .catch(err => {
          throw err;
        })
    },
    getDadosKpisMunicipioCompetenciaAnterior() {
      return DashboardServicosService.getDadosKpisTotalizadosVisaoMunicipios(
        dayjs(this.competencia).subtract(1, 'month').format('YYYY-MM'),
        this.servicoSelecionado,
        this.regionalSelecionada
      )
        .then(res => {
          this.dadosKpisTotalizadosMunicipio = res;
        })
        .catch(err => {
          throw err;
        });
    },
    getMetasKpisByMunicipio() {
      return DashboardServicosService.getMetasKpisMunicipios(
        this.competencia,
        this.servicoSelecionado,
        this.regionalSelecionada
      )
        .then(response => {
          this.metasKpisMunicipio = response;
        })
        .catch(err => {
          throw err;
        });
    },
  },
  watch: {
    activatedTab() {
      this.getDados();
    },
    competencia() {
      this.getDados();
    },
    servicoSelecionado() {
      this.getDados();
    },
    regionalSelecionada(){
      this.getDados();
    }
  }
}

</script>

<style>

</style>